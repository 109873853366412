<template>
  <div class="zone-container">
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    main() {
      this.$router.push({
        name: "allZone.index"
      }).catch(() => {

      })
    },

  }
}
</script>

<style scoped lang="scss">
.zone-container {
  width: 100%;
  height: 100vh;
}
</style>
